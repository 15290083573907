<template>
  <div>
    <DefaultScene/>
  </div>
</template>

<script>
import DefaultScene from "@/components/3D/DefaultScene";

export default {
  name: '3D-Scene',
  components: {
    DefaultScene,
  },
}
</script>

<style scoped>

</style>