<template>
  <div id="3d-scene" class="three-d-scene">

  </div>
</template>

<script>
import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';

export default {
  data() {

    return {
      // container:  null,
      // camera:     null,
      // scene:      null,
      // renderer:   null,
      // controls:   null,
      //
      // theta: 0,
      // cube: null,
    }

  },
  methods: {
    init() {
      /* Создаем сцену */
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color( 0xFFFFFF );

      this.raycaster = new THREE.Raycaster();

      /* Рендерим */
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      document.getElementById( '3d-scene' ).appendChild( this.renderer.domElement );

      /* Позиция камеры */
      this.camera = new THREE.PerspectiveCamera( 100, window.innerWidth / window.innerHeight, 0.1, 1000 );


      /* Позиция камеры */
      // const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
      // const points = [];
      // points.push( new THREE.Vector3( - 10, 0, 0 ) );
      // points.push( new THREE.Vector3( 0, 10, 0 ) );
      // points.push( new THREE.Vector3( 10, 0, 0 ) );
      //
      // const geometry = new THREE.BufferGeometry().setFromPoints( points );
      //
      // const line = new THREE.Line( geometry, material );
      // this.scene.add( line );

      /* Добавляем осцещение */
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;



      const sphereGeometry = new THREE.SphereGeometry( 5, 32, 32 );
      const sphereMaterial = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
      const sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
      sphere.castShadow = true; //default is false
      sphere.receiveShadow = false; //default
      this.scene.add( sphere );



      const light = new THREE.PointLight( 0xffffff, 1, 100 );
      light.position.set( 0, 10, 4 );
      light.castShadow = true;
      this.scene.add( light );

      this.camera.position.z = 5;
      this.camera.position.y = -2;

      /* Вращение 3D мышкой, контейнер с объектом должен быть на первом плане */
      this.controls = new TrackballControls( this.camera, this.renderer.domElement );
      this.controls.minDistance = 5;
      this.controls.maxDistance = 6000;
      this.controls.addEventListener( 'change', this.render );

      this.theta = 0;

      window.addEventListener( 'resize', this.onWindowResize );

      this.INTERSECTED = null;
      this.pointer = new THREE.Vector2();
      document.addEventListener( 'mousemove', this.onPointerMove );
    },
    onWindowResize() {

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize( window.innerWidth, window.innerHeight );

      this.render();

    },
    onPointerMove( event ) {

      this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
      this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    },
    animate() {

      requestAnimationFrame( this.animate );

      this.controls.update();
      this.render();

    },
    render() {

      /* Вращение камеры вокруг 3D модели */
      // this.theta += 0.1;
      //
      // this.camera.position.x = this.radius * Math.sin( THREE.MathUtils.degToRad( this.theta ) );
      // this.camera.position.y = this.radius * Math.sin( THREE.MathUtils.degToRad( this.theta ) );
      // this.camera.position.z = this.radius * Math.cos( THREE.MathUtils.degToRad( this.theta ) );
      // this.camera.lookAt( this.scene.position );
      //
      // this.camera.updateMatrixWorld();

      this.renderer.render( this.scene, this.camera );

      // find intersections

      this.raycaster.setFromCamera( this.pointer, this.camera );

      const intersects = this.raycaster.intersectObjects( this.scene.children, false );

      if ( intersects.length > 0 ) {

        if ( this.INTERSECTED != intersects[ 0 ].object ) {

          if ( this.INTERSECTED ) this.INTERSECTED.material.emissive.setHex( this.INTERSECTED.currentHex );

          this.INTERSECTED = intersects[ 0 ].object;
          this.INTERSECTED.currentHex = this.INTERSECTED.material.emissive.getHex();
          this.INTERSECTED.material.emissive.setHex( 0xff0000 );

        }

      } else {

        if ( this.INTERSECTED ) this.INTERSECTED.material.emissive.setHex( this.INTERSECTED.currentHex );

        this.INTERSECTED = null;

      }

    },
  },
  mounted() {

    this.init();
    this.animate();

  }
}
</script>

<style scoped>
.three-d-scene {
  width: 100%;
  overflow: hidden;
}
</style>
